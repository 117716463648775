<template>
    <div class="home">
      <DiscogTimeline />
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import DiscogTimeline from '@/components/DiscogTimeline.vue'
  
  export default {
    name: 'DiscogView',
    components: {
        DiscogTimeline
    }
  }
  </script>
  