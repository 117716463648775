<template>
  <div id="timeline-content">
    <h1>Timeline/Discography</h1>
    <ul class="timeline">
      <div v-for="record in records" :key="record.timelineDate" class="record">
        <li class="event" :data-id="record.timelineDate" :data-date="record.timelineDateText">
          <div class="row">
            <div class="col">
              <h2>{{ record.title }}</h2>
              <img 
                v-if="record.imageLocation" 
                :src="`https://www.stateofbeing.com/${record.imageLocation}`" 
                :key="record.imageLocation" 
                :width="350"
              >
            </div>
            <div v-if="record.rirNumber" class="col">
              <span class="cat-number">{{ record.rirNumber }}</span>
              <ul class="tracklist">
                <li v-for="[key, value] in Object.entries(JSON.parse(record.tracksJson) != null ? JSON.parse(record.tracksJson) : {})" :key="key">
                  {{ `${key}. ${value}` }}
                </li>
              </ul>
            </div>
          </div>
          <div v-for="quote in JSON.parse(record.reviewQuotesJson)" :key="quote" class="quote">
            <div>{{quote}}</div>
          </div>
          <div v-for="note in JSON.parse(record.noteTextJson)" :key="note" class="note">
            <div>{{note}}</div>
          </div>
        </li>
      </div>
    </ul>
  </div>
</template>
<script>
import axios from 'axios';
//<!-- && record.coverImageLocation != null && record.coverImageLocation != 'null' && record.coverImageLocation.length > 0" -->
// import { defineComponent, onMounted } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "DiscogTimeline",
  data () {
    return {
      showImages: false,
      records: []
    }
  },
  methods: {
    fetchRecords: function () {
      const baseURI = 'https://www.stateofbeing.com/api/timeline/read.php'
      axios.get(baseURI)
      .then((result) => {
        console.log(result.data);
        this.records = result.data.records
      })
    }
  },
  mounted() {
    this.fetchRecords();
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Base */
body {
  background: #252827;
  font-size: 16px;
}
.record {
  padding-bottom: 1em;
}
.quote {
  font-size: 0.8em;
  font-style: italic;
  padding-bottom: 0.6em;
}
.note {
  font-size: 0.8em;
}
.cat-number {
  font-size: 0.8em;
}
p {
  font-weight: 400;
}
a {
  color: #6c6d6d;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.3em;
  font-size: 0.6em;
  font-weight: 500;
  background: #252727;
  padding: 0.3rem 1rem;
  margin: 1.9rem 0 0 0;
  float: right;
}
a:hover {
  color: black;
  background: #004ffc;
  border-bottom: 0.35em solid black;
}
strong {
  font-weight: 700;
}
h1 {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.5px;
  color: black;
  font-weight: 600;
  font-size: 2.4em;
}
#timeline-content {
  margin-top: 50px;
  text-align: center;
}
/* Timeline */
.timeline {
  border-left: 4px solid #004ffc;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(255, 255, 255, 0.03);
  color: rgba(25, 25, 25, 0.9);
  font-family: 'Montserrat', sans-serif;
  /* margin: 50px auto; */
  margin-left: 20%;
  /* letter-spacing: 0.5px; */
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  font-weight: 400;
  max-width: 90%;
}
.timeline h1 {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.5px;
  font-weight: 400;
  font-size: 1.4em;
}
.timeline h2,
.timeline h3 {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.5px;
  font-weight: 500;
  font-size: 1.4em;
}
.timeline .event {
  border-bottom: 1px dashed rgba(25, 25, 25, 0.1);
  padding-bottom: 25px;
  margin-bottom: 50px;
  position: relative;
}
.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}
.timeline .event:before,
.timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}
.timeline .event:before {
  left: -217.5px;
  color: rgba(0, 0, 0, 0.5);
  content: attr(data-date);
  text-align: right;
  font-weight: 400;
  font-size: 0.9em;
  letter-spacing: 0.5px;
  min-width: 120px;
  font-family: 'Montserrat', sans-serif;
}
.timeline .event:after {
  box-shadow: 0 0 0 4px #004ffc;
  left: -57.85px;
  background: #313534;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
  top: 5px;
}

/* tracks */
ul.tracklist {
  padding-inline-start: 0px;
  font-size: 0.8em;
}

ul.tracklist li {
  list-style-type: none;
}

</style>