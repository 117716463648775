<template>
  <div class="home">
    <BioText />
    <br>
    <VideoDisplay/>
    <br>
    <NewsService msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import NewsService from '@/components/NewsService.vue'
import BioText from '@/components/BioText.vue'
import VideoDisplay from '@/components/VideoDisplay.vue'

export default {
  name: 'HomeView',
  components: {
    NewsService,
    BioText,
    VideoDisplay
  }
}
</script>
<style>
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }
</style>