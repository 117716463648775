import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

//bootstrap vue
import BootstrapVue3 from 'bootstrap-vue-3';
// app.use(BootstrapVue3);

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

createApp(App).use(router).use(BootstrapVue3).mount('#app')
