<template>
    <div class="videos">
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-5">
                <h2 class="video-title">Virtual Addiction (clip)</h2>
                <p class="video-title">LIVE</p>
                <p class="video-title">at The Beachland Ballroom (2024).</p>
            </div>
            <div class="col-5">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/M0mXgVFv1gI?si=Dpa4m8V41eNUFVQX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div class="col-1">
            </div>
        </div>
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-5">
                <h2 class="video-title">Coming From</h2>
                <p class="video-title">Originally from <i>The Misinformation Age</i> (2020).</p>
            </div>
            <div class="col-5">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/C-KIVOu8eJo?si=s2fNvTrv30TddgUx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div class="col-1">
            </div>
        </div>
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-5">
                <h2 class="video-title">All the Chaos</h2>
                <p class="video-title">From <i>The Misinformation Age</i> (2017).</p>
            </div>
            <div class="col-5">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/tEv0tM6mGNE?si=8rxW6BIV1bwQNYad" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div class="col-1">
            </div>
        </div>
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-5">
                <h2 class="video-title">Virtual Addiction</h2>
                <p class="video-title">FLASHBACK MIX</p>
                <p class="video-title">Originally from <i>Dysfunctional Vision</i> (1994)</p>
            </div>
            <div class="col-5">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/OR2Efwjullc?si=TY3MXdnTLx0izAZ7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div class="col-1">
            </div>
        </div>

        <!--
      <a data-flickr-embed="true" href="https://www.flickr.com/groups/94586794@N00/pool/" title="State Of Being"><img src="https://live.staticflickr.com/766/22569609717_65a30e35e1.jpg" width="640" height="480" alt="State Of Being"/></a>
      -->
    </div>
</template>
  <!--
  <script async src="//embedr.flickr.com/assets/client-code.js" charset="utf-8"></script>
  -->

<style>
.video-title {
    text-align: right;
}</style>